<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog width="800" v-model="detailsDialog">
      <v-card style="overflow-y: scroll" height="80vh">
        <v-card-title class="mb-3">جزئیات</v-card-title>
        <v-card-text>
          <div v-if="Busy" class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <div v-for="(item, index) in labDetials" :key="index">
              <h5 class="text-right mb-3">{{ item.section }}:</h5>
              <b-table
                small
                responsive
                show-empty
                :fields="Fields"
                :items="item.parts"
                class="mb-4"
                empty-text="موردی برای نمایش وجود ندارد"
                empty-filtered-text="موردی برای نمایش وجود ندارد"
                :busy="Busy"
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template v-slot:cell(normalRange)="data">
                  <div
                    style="
                      text-align: center;
                      vertical-align: middle;
                      direction: ltr !important;
                    "
                    dir="ltr"
                  >
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(labUnit)="data">
                  <div
                    style="
                      text-align: center;
                      vertical-align: middle;
                      direction: ltr !important;
                    "
                    dir="ltr"
                  >
                    {{ data.value }}
                  </div>
                </template>
                <div slot="table-busy" class="text-center primary--text my-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                </div>
              </b-table>
              <hr class="mb-8" />
            </div>
            <!-- <v-btn
              class="primary-btn pe-5 ps-5 py-2"
              @click="openConfirmDialog(selected)"
            >
              <h6 class="mt-1">
                <v-icon left>check_circle</v-icon>
                تایید
              </h6>
            </v-btn> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <mobile-text v-if="deviceType == 'mobile'" :title="'جوابدهی'" />
    <v-col cols="12 mx-auto" v-else>
      <v-card-title style="background-color: transparent !important">
        <h3>جوابدهی</h3>
      </v-card-title>
      <div v-if="step == 1">
        <v-card class="cards pa-3">
          <div class="pa-2">
            <v-file-input
              v-model="file"
              ref="file"
              outlined
              dense
              label=" بارگذاری گزارش آزمایش"
              prepend-inner-icon="upload_file"
              prepend-icon=""
              accept=".xlsx"
              class="pt-1 ps-1 pe-1 file-input file-details-hidden"
              @change="CSVtoJson"
            ></v-file-input>
            <!-- <v-textarea
              @keyup.enter.native="input ? inputToArray : ''"
              label="ورودی جواب آزمایش‌ها"
              outlined
              rows="12"
              v-model="input"
            >
            </v-textarea>
            <v-btn class="primary-btn" :disabled="!input" @click="inputToArray"
              >تبدیل</v-btn
            > -->
          </div>
          <hr />
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              hide-details
              single-line
              class="text-right w-80 mb-2 search-input dr-search"
              filled
              rounded
              clearable
            ></v-text-field>
            <b-table
              :items="jsonOutput"
              :fields="labDetailsFields"
              responsive
              show-empty
              empty-text="آزمایشی برای نمایش وجود ندارد"
              empty-filtered-text="آزمایشی برای نمایش وجود ندارد"
              :current-page="CurrentPage"
              :per-page="PerPage"
              :filter="Filter"
              @filtered="onFiltered"
              class="pa-2"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(normalRange)="data">
                <div
                  style="
                    text-align: center;
                    vertical-align: middle;
                    direction: ltr !important;
                  "
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(labUnit)="data">
                <div
                  style="
                    text-align: center;
                    vertical-align: middle;
                    direction: ltr !important;
                  "
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-row>
              <v-col>
                <v-select
                  label="تعداد در هر صفحه:"
                  style="width: 150px"
                  v-model="PerPage"
                  :items="perPageOptions"
                  item-text="text"
                  item-value="value"
                  class="me-2 ms-2"
                ></v-select>
              </v-col>
              <v-col class="text-left">
                <v-btn class="primary-btn mt-4 me-2 ms-2" @click="sendJson">
                  <v-icon left>send</v-icon>
                  ارسال
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <div v-if="step == 2">
        <v-tabs
          fixed-tabs
          class=""
          background-color="transparent"
          v-model="tab"
        >
          <v-tab class="tab-title"> موفق </v-tab>
          <v-tab class="tab-title"> ناموفق </v-tab>
        </v-tabs>
        <v-card class="br-card pa-3 tab-item">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <b-table
                responsive
                show-empty
                :fields="successFields"
                :items="importReport.importReportSuccess"
                empty-text="موردی برای نمایش وجود ندارد"
                empty-filtered-text="موردی برای نمایش وجود ندارد"
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template v-slot:cell(operation)="data">
                  <div class="text-center">
                    <v-btn
                      class="primary-btn text-center pe-5 ps-5 py-2"
                      @click="openDetails(data.item)"
                    >
                      <h6 class="mt-1">
                        <v-icon left>visibility</v-icon>
                        جزئیات
                      </h6>
                    </v-btn>
                  </div>
                </template>
              </b-table>
            </v-tab-item>
            <v-dialog v-model="confirmDialog" max-width="600px">
              <v-card>
                <v-card-title>
                  <span><h3>تایید آزمایش</h3></span>
                </v-card-title>
                <v-card-text class="text-right">
                  <h4 class="common-text">
                    آیا از تایید آزمایش
                    {{ this.selected.LaboratoryTurnNumber }}
                    مطمئن هستید ؟
                  </h4>
                  <h6 class="red--text pt-4">
                    <v-icon color="red">mdi-alert</v-icon>
                    <span
                      >در صورت تایید نهایی، پاسخ آزمایش قابل تغییر
                      نمی‌باشد.</span
                    >
                  </h6>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="primary-btn" outlined @click="confirmLab">
                    تایید نهایی
                  </v-btn>
                  <v-btn
                    class="red-btn"
                    outlined
                    @click="confirmDialog = false"
                  >
                    لغو
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-tab-item>
              <b-table
                responsive
                show-empty
                :fields="failFields"
                :items="importReport.importReportFail"
                empty-text="موردی برای نمایش وجود ندارد"
                empty-filtered-text="موردی برای نمایش وجود ندارد"
              >
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.index + 1 }}
                  </div>
                </template>
              </b-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-col>
  </div>
</template>
<script>
import XLSX from "xlsx";
const MobileText = () => import("@/components/MobileText");
export default {
  components: { MobileText },
  data() {
    return {
      role: "",
      labData: {},
      file: [],
      jsonOutput: [],
      busy: false,
      input: "",
      output: [],
      CurrentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: false,
      step: 1,
      importReport: {},
      tab: null,
      confirmDialog: false,
      Fields: [
        { key: "subject", label: "عنوان" },
        { key: "labResult", label: "جواب" },
        { key: "normalRange", label: " رنج نرمال" },
        { key: "labUnit", label: "واحد " },
        { key: "labDescription", label: "توضیحات " },
      ],
      successFields: [
        { key: "index", label: "#" },
        { key: "LaboratoryTurnNumber", label: "شماره پذیرش آزمایش" },
        { key: "result", label: "نتیجه" },
        { key: "operation", label: "جزئیات" },
      ],
      failFields: [
        { key: "index", label: "#" },
        { key: "LaboratoryTurnNumber", label: "شماره پذیرش آزمایش" },
        { key: "reason", label: "نتیجه" },
      ],
      labDetailsFields: [
        { key: "index", label: "#" },
        { key: "LaboratoryTurnNumber", label: "شماره پذیرش آزمایش" },
        { key: "subject", label: "عنوان آزمایش" },
        { key: "labResult", label: "جواب" },
        { key: "normalRange", label: "نرمال رنج" },
        { key: "labUnit", label: "واحد" },
        { key: "labSection", label: "بخش" },
        { key: "labDescription", label: "توضیحات" },
      ],
      selected: {},
      labDetials: [],
      detailsDialog: false,
    };
  },
  methods: {
    getLabDetails() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/all",
          {
            labId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.importReportFail = res.data.importReportFail;
            this.importReportSuccess = res.data.importReportSuccess;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    inputToArray() {
      let batchDataArray = this.input.split(",").join("").split("\n");
      batchDataArray.shift();
      this.output = batchDataArray.map((x) => {
        return {
          labDescription: x.split("\t")[16] ? x.split("\t")[16].trim() : "",
          labSection: x.split("\t")[15] ? x.split("\t")[15].trim() : "",
          labUnit: x.split("\t")[14] ? x.split("\t")[14].trim() : "",
          normalRange: x.split("\t")[13] ? x.split("\t")[13].trim() : "",
          labResult: x.split("\t")[12] ? x.split("\t")[12].trim() : "",
          subject: x.split("\t")[11] ? x.split("\t")[11].trim() : "",
          LaboratoryTurnNumber: x.split("\t")[0] ? x.split("\t")[0].trim() : "",
        };
      });
    },
    CSVtoJson() {
      // the user uploades the csv file, this function converts it to Json, we used Promise cause it takes time to get the output file
      const promise1 = new Promise((resolve, reject) => {
        const selectedFile = this.file;
        let fileType = selectedFile.name.split(".").at(-1);
        if (fileType.toLowerCase() == "csv") {
          //Check the file type to see if the type is csv or xlsx
          //we use FileReader to read the file and use the needed functions like 'readAsText'
          const reader = new FileReader();
          reader.readAsText(selectedFile);
          reader.onload = function (e) {
            const contents = e.target.result;
            //seperated the csv file lines (splitting with '\n')
            const lines = contents.split("\n");
            // seperated the csv header items (splitting line 0 with ',') / in csv the items are seperated by ','
            const header = lines[0].split(",");
            //we seperate each item by ',' and map each item to its related header item
            var csvOutput = lines.slice(1).map((line) => {
              const fields = line.split(",");
              return Object.fromEntries(header.map((h, i) => [h, fields[i]]));
            });

            // change persian titles to english standard
            csvOutput = JSON.stringify(csvOutput);
            csvOutput = csvOutput.replaceAll(
              " شماره پذیرش ",
              "LaboratoryTurnNumber"
            );
            csvOutput = csvOutput.replaceAll("نام آزمایش", "subject");
            csvOutput = csvOutput.replaceAll("جواب", "labResult");
            csvOutput = csvOutput.replaceAll("نرمال رنج", "normalRange");
            csvOutput = csvOutput.replaceAll("واحد", "labUnit");
            csvOutput = csvOutput.replaceAll("بخش", "labSection");
            csvOutput = csvOutput.replaceAll("توضیحات", "labDescription");

            csvOutput = JSON.parse(csvOutput);
            //resolve the csvOutput (jsonOutput)
            resolve(csvOutput);
          };
        } else if (fileType.toLowerCase() == "xlsx") {
          const reader = new FileReader();
          //if type was xlsx we read the data az binary string
          reader.readAsBinaryString(selectedFile);
          // then we use xlsx package to read our data as binary
          reader.onload = function (e) {
            const contents = e.target.result;
            let workbook = XLSX.read(contents, { type: "binary" });
            workbook.SheetNames.forEach((sheet) => {
              // and here XLSX convert every sheet of xlsx to array
              var excelOutput = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[sheet]
              );
              // change persian titles to english standard
              excelOutput = JSON.stringify(excelOutput);
              excelOutput = excelOutput.replaceAll(
                "شماره پذیرش",
                "LaboratoryTurnNumber"
              );
              excelOutput = excelOutput.replaceAll("نام آزمایش", "subject");
              excelOutput = excelOutput.replaceAll("جواب", "labResult");
              excelOutput = excelOutput.replaceAll("نرمال رنج", "normalRange");
              excelOutput = excelOutput.replaceAll("واحد", "labUnit");
              excelOutput = excelOutput.replaceAll("بخش", "labSection");
              excelOutput = excelOutput.replaceAll("توضیحات", "labDescription");
              excelOutput = JSON.parse(excelOutput);
              resolve(excelOutput);
            });
          };
        }
      });

      //we get the value of the promise (here is output) and assign in to the declared variable (jsonOutput)
      promise1.then((value) => {
        //anything we want to do with jsonOutput should be declared inside promise then
        this.busy = true;
        this.vLoading = true;
        this.jsonOutput = value;
        this.TotalRows = this.jsonOutput.length;
        this.vLoading = false;
      });
    },
    sendJson() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/importResults",
          {
            results: this.jsonOutput,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.importReport = res.data;
            this.vLoading = false;
            this.step = 2;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    openDetails(item) {
      this.selected = item;
      this.detailsDialog = true;
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/id",
          {
            labRequestId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.labDetials = res.data.sections;
          } else {
            this.detailsDialog = false;
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.detailsDialog = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    openConfirmDialog(item) {
      this.selected = item;
      this.confirmDialog = true;
    },
    confirmLab() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/approveLabRequestId",
          {
            labRequestId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.importReport.importReportSuccess.splice(
              this.importReport.importReportSuccess.indexOf(
                this.importReport.importReportSuccess.filter(
                  (x) => x.id == this.selected.id
                )[0]
              ),
              1
            );
            this.detailsDialog = false;
            this.confirmDialog = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.confirmDialog = false;
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
          this.confirmDialog = false;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    //getLabDetails();
  },
};
</script>
